@import "../../styles/common";

.sub-articles {
  @include article-section;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__item {
    width: 100%;
    padding-left: 0;
    color: $knowledge-base-main-text;
    text-align: left;
    @include no-wrap-text();

    &:hover {
      color: $knowledge-base-primary-text;
    }
  }
}

.sub-articles-loader {
  @include article-section
}
