@import "../../styles/common";

.articleActions {
  display: flex;
  margin-left: auto;

  &__action {
    @include article-action();

    &_main {
      margin-left: 10px;
    }

    &_publish, &_save {
      width: 130px;
      background-color: $knowledge-base-primary-text;
    }
    &_history {
      width: 147px;
      background-color: $knowledge-base-primary-text;
    }

    &_edit {
      width: 100px;
      border-color: $knowledge-base-primary-text;
    }

    &_additional {
      color: $knowledge-base-main-text;

      &:hover {
        color: $knowledge-base-primary-text;
      }
    }

    &_error.btn {
      color: $danger;

      &:hover {
        color: $danger;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &__favorite {
      display: flex;
      align-items: center;

      &__spinner {
        height: 15px;
        width: 15px;
        color: var(--primary);
      }
    }
  }

  &__icon {
    margin-right: 10px;

    &_single {
      margin-right: 0;
    }

    &_favorited > svg > path {
      fill: $ct-orange-3;
      d: path("M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6z");
    }
  }
}

.articleCompareModal {
  overflow: unset;
}
