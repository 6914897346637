@use "../../styles/common";

$fullscreen-loader-text-size: common.$public-form-h2-size * 1.54;
$fullscreen-loader-spinner-color: var(--primary);
$fullscreen-loader-spinner-size: $fullscreen-loader-text-size * 2;
$fullscreen-loader-title-spacing: common.$ct-font-size-base * 1.8;

.menusFrame {
    border-style: none;
    min-height: common.$height-without-header;
    position: relative;
    bottom: 0;
    right: 0;
}

.loader {
    display: flex;
    height: common.$height-without-header;
    text-align: center;
    align-items: center;

    &__content {
        flex-direction: column;
        flex-grow: 2;
    }

    &__spinner {
        color: $fullscreen-loader-spinner-color;
        width: $fullscreen-loader-spinner-size;
        height: $fullscreen-loader-spinner-size;
        margin-bottom: $fullscreen-loader-title-spacing;
    }
}
