@use "../../styles/common" as common;

@mixin icon {
    @include common.transition-all();
    cursor: pointer;
    color: common.$sub-text-color;
    
    &:hover {
        color: var(--primary);
    }
}

.fileUploader {
    $self: &;
    display: flex;
    position: relative;
    min-width: 150px;
    font-size: common.$ct-font-size-base;

    &__deleteIcon {
        @include icon;
        position: absolute;
        right: 31px;
    }

    &__downloadIcon {
        @include icon;
        position: absolute;
        right: 0;
    }

    &__leftIcon {
        @include icon;
        margin-right: 9px;
    }

    &__fileLink {
        display: none;
    }

    &_disabled {
        #{$self}__deleteIcon:hover,
        #{$self}__downloadIcon:hover,
        #{$self}__leftIcon:hover {
            color: common.$sub-text-color;
            cursor: default;
        }
    }
}
