@use "../../styles/common";

$title-font-size: common.$font-size-base * 1.14;

.addAgentBlockForm {
  @include common.page-layout-sidebar();
  $self: &;

  &__content {
    padding: 10px 22px 0;
  }

  &__agentTypesTitle {
    padding-bottom: 24px;
    font-size: $title-font-size;
  }

  &__agentTypes {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  &__agentTypeIcon {}
}
