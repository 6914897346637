@use "../../styles/common";

.projectCard {
    border-radius: common.$border-radius;
    transition: common.$transition-base;
    display: flex;
    flex-direction: row;
    align-items: center;
    $self: &;

    &_short {
        flex-direction: column;
        #{$self}__image {
            margin-right: 0;
        }
        #{$self}__name {
            font-size: common.$menu-item-title-font-size;
        }
    }

    &__image {
        @include common.fit-image;
        border-radius: common.$border-radius;
        border: 1px solid common.$gray-400;
        height: common.$project-image-height;
        width: common.$project-image-weight;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    &__imageItem {
        text-align: center;
    }

    &__name {
        @include common.no-wrap-text("clip");
        @include common.text-truncate();

        font-size: common.$fz-16;
        max-width: 100%
    }

    &__nameItem {
        user-select: none;
    }
}