@use "../../../../styles/common";

.item {
    position: absolute;
    top: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    width: 100%;
    height: 100%;
    user-select: none;

    &_alone {
        top: 0;
        opacity: 1;
    }

    &__title {
        @include common.no-wrap-text();
    }
}
