@use "../../../../../../styles/common";
@use "../../../../variables.scss";

.header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 32px;
    padding: 0 20px;

    &__title {
        margin: 0;
        font-weight: 400;
        font-size: common.$fz-20;
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: common.$fz-16;
    }

    &__indicator {
        border-radius: 50%;
        width: 8px;
        height: 8px;

        @each $status, $color in variables.$mass-mailing-indicators {
            &_#{$status} {
                background-color: #{$color};
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: auto;
    }
}
